import React from "react"
import { SEO, Layout } from "../components/index"

const NotFoundPage = () => (
  <Layout>
    <h1>Page Not Found</h1>
    <h2>
      The page you were looking for is not here. If you believe this to be in
      error, you can contact us at{" "}
      <a href="mailto:beholdmessiah@gmail.com">beholdmessiah@gmail.com</a> for
      assistance.
    </h2>
  </Layout>
)

export default NotFoundPage

export function Head() {
  return (
    <SEO
      title={"Page not Found"}
      description={"The page you were looking for is not here."}
    >
      <meta name="robots" content="noindex, nofollow" />
    </SEO>
  )
}
